/* Small only */

@media screen and (max-width: 39.9375em) {}


/* Medium and up */

@media screen and (min-width: 40em) {}


/* Medium only */

@media screen and (min-width: 40em) and (max-width: 63.9375em) {}


/* Large and up */

@media screen and (min-width: 64em) {}


/* Large only */

@media screen and (min-width: 64em) and (max-width: 74.9375em) {}

body {
	color: #575757;
	font-family: 'Open Sans', sans-serif !important;
}

h1,
h2,
h3,
h4,
h5 {
	font-family: 'Open Sans', sans-serif;
	color: #575757;
}

h1 {
	font-weight: 300;
}

a {
	color: #575757;
	text-decoration: none;
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea,
select {
	margin: 0;
}

label {
	margin: 0 0 1rem;
	&.error {
		color: #f00;
	}
}

.text-center {
	text-align: center;
}
.text-left {
	text-align: left;
}
.text-right {
	text-align: right;
}
.white {
	color: #fff;
}

@media screen and (max-width:640px) {
	.small-text-center {
		text-align:center;
	}
}

/****** Navigation *****/

.topMenu {
	padding: 15px 0px;
	.menuTitle {
		color: #fff;
		position: relative;
		vertical-align: middle;
		margin: 0px;
		li {
			border-right: 2px solid #fff;
			display: inline-block;
			line-height: 1;
			list-style-type: none;
			&:last-of-type {
				border-right: 0;
			}
			a {
				font-size: 18px;
				text-transform: uppercase;
				padding: 0px 10px;
				font-family: 'Open Sans', sans-serif;
			}
		}
	}
}


/* Header */

.logo {
	margin: 0px 0px 15px;
	max-height: 90px;
}

.headBG {
	background-repeat: no-repeat;
	background-size: cover;
	height: auto;
	min-height: 500px;
	.headTitle {
		color: #fff;
		padding: 100px 0px;
	}
	.headText {
		color: #fff;
	}
	img {
		padding: 20px 0;
	}
}


/* Buttons */

.boxButton {
	display: block;
	font-size: 16px;
	font-weight: 700;
	line-height: 25px;
	margin: 20px auto;
	width: 200px;
}

.boxButton:hover {
	background-color: #326f2b;
}
.slanted-button {
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    transform: skewX(-20deg);
    background-color: #00b6de;
    display: inline-block;
    padding: 5px 28px;
    margin: 10px;
    color: #fff;
    text-align: center;
    &:hover {
    	background-color:#05a7cb;
    }
    
    a.slanted {
	    color: #fff;
	    -ms-transform: skewX(20deg);
	    -webkit-transform: skewX(20deg);
	    transform: skewX(20deg);
	    display: inline-block;
        font-family: 'Open Sans';
	    font-weight: 600;
	    font-size: 18px;
	    &:hover {
	    	color:#fff;
	    }
	}
}
@media screen and (max-width:640px){
	.slanted-button {
		width:95%;
	}
}

/* Body */

.body-section {
	padding: 40px 0 0;
}

.body-title {
	color: #575757;
}

.body-text {
	color: #575757;
	line-height: 30px;
	padding: 40px 0 0;
}


/* Show Hide Section*/

#body-show-hide {
	.box {
		display: none;
		&.show {
			display: block!important;
		}
	}
	overflow: hidden;
	.box-bg {
		background: #f3f3f3;
		margin-top: 40px;
		h4 {
			margin: 40px 0 10px;
		}
	}
	.box-container {
		height: 400px;
	}
	.show-hide-box {
		max-width: 300px;
		margin: 40px auto 0;
		h4:hover {
			color: #66ad5d;
		}
	}
	.boxes {
		width: 300px;
		background-color: grey;
		margin: 40px auto;
		.boxTitle {
			color: #fff;
			padding: 15px 0px;
			text-transform: uppercase;
			margin: 0px;
		}
		.boxSub {
			margin: 0px;
			color: #fff;
			text-transform: uppercase;
		}
		a.boxButton {
			width: 100%;
			line-height: 1;
			color: #fff;
			&:hover {
				color: #fff;
			}
		}
	}
}


/******* Questions ******/

#questions {
	border-top: 3px solid #d4d8da;
	padding: 20px 0;
	 
	h1 {
		font-size:42px;
	}
}


/***** Registration Pages ******/

.body-registration {
	border-top: 3px solid #ddd;
	#presentation .large-8,
	.presentation .large-9 {
		padding: 30px 50px 0 0;
		img {
			max-width: 150px;
		}
	}
	.register-form {
		background: #f3f3f3;
		padding: 50px 20px;
	}
}


/***** Resource Library *******/
.tabs {
	border:0px;
}
.tabs-title > a {
	font-size: 28px;
}
.tabs-content {
	border:0px;
	font-size: 28px;
}

#resourceLibrary {
	border-bottom: 3px solid #ddd;
	padding: 10px;
	li {
		border-right: 2px solid #d7d7d7;
		width: 33%;
		margin-bottom: 5px;
		&:last-child {
			border-right: none;
			padding-bottom: 0;
		}
	}
}

#demandResource {
	border-bottom: 3px solid #ddd;
	padding: 10px;
	li {
		border-right: 2px solid #d7d7d7;
		width: 50%;
		margin-bottom: 5px;
		&li:last-child {
			border-right: none;
			padding-bottom: 0;
		}
	}
}

#salesResources {
	border-bottom: 3px solid #ddd;
	padding: 10px;
	li {
		border-right: 2px solid #d7d7d7;
		width: 50%;
		margin-bottom: 5px;
		&:last-child {
			border-right: none;
			padding-bottom: 0;
		}
	}
}

#technicalResources {
	border-bottom: 3px solid #ddd;
	padding: 10px;
	li {
		border-right: 2px solid #d7d7d7;
		width: 50%;
		margin-bottom: 5px;
		&:last-child {
			border-right: none;
			padding-bottom: 0;
		}
	}
}


/***** Footer ******/

.footer {
	padding: 30px 0px;
	p {
		color: #fff;
		font-size: 12px;
		margin: 0px;
	}
}


/*Veeam*/

.veeam-branded {
	.spacer {
    padding: 10px;
}
	a {
		color: #53b847;
		&:hover {
			color: #52b949;
		}
	}
	.tabs-title>a:focus,
	.tabs-title>a[aria-selected='true'] {
		color: #555;
		background: none;
	}
	
	.box {
		h4 {
			color: #52b949;
		}
	}
	a.boxButton,
	.button.boxButton {
		background-color: #66ad5d;
		&:hover {
			background-color: #326f2b;
		}
	}
	.topMenu,
	.footer {
		background-color: #575757;
	}
	.body-registration {
		h3,
		a:hover {
			color: #52b949;
		}
	}
	#resourcelibrary .tabs-resource-library ul li{
		padding: 10px;
    	color: #53b847;
    	font-size: 28px;
    	font-family: open-sans;
    	font-weight: 300;
	}
	#resourcelibrary .resource-item li.camera:before, #resourcelibrary .resource-item li.podcast:before{
		font-family: FontAwesome;
    	float: left;
    	margin-right: .5em;
    	color: #53b847;
    	position: absolute;
    	margin-left: -40px;
	}
	#resourcelibrary .resource-item li.camera:before{
		    content: '\f03d';
	}
	#resourcelibrary .resource-item {
    text-align: left;
    margin-left: 40px;
	}
}


/*Simplivity*/

.simplivity-branded {
	.headBG {
		height: auto;
		min-height: auto;
		padding-bottom: 23%;
		background-position-x: 50%;
	}
	a {
		color: #37a8e0;
		&:hover {
			color: #349ed3;
		}
	}
	.box {
		h4 {
			color: #37a8e0;
		}
	}
	a.boxButton,
	.button.boxButton {
		background-color: #37a8e0;
		&:hover {
			background-color: #349ed3;
		}
	}
	.topMenu,
	.footer {
		background-color: #000;
	}
	.body-registration {
		h3,
		a:hover {
			color: #37a8e0;
		}
	}
	.body-thank-you {
		margin-bottom: 100px;
	}
	@media screen and (max-width:640px) {
		.headBG {
			background-size: contain;
			padding-bottom: 36%;
			.headTitle {
				display: none;
			}
		}
	}
}


/*Discover*/

.discover-branded {
	.headBG {
		height: auto;
		min-height: auto;
		padding-bottom: 10%;
		background-position-x: 50%;
		
		.headTitle {
			padding:20% 0 0;
		}
	}
	a {
		color: #00b6de;
		&:hover {
			color: #05a7cb;
		}
	}

	.topMenu,
	.footer {
		background-color: #0068ac;
		a {
			color:#fff;
			img {
				margin:0 5px;
			}
		}
	}
	.topMenu {
		    padding: 32px 0px;
		    a {
		    	line-height:35px;
		    	color: #fff;
		    }
		    
		}

	.body-thank-you {
		margin-bottom: 100px;
	}

}

@media screen and (max-width:640px) {
	.discover-branded {
		.headBG {
			background-size: contain;
			padding-bottom: 36%;
			.headTitle {
				display: none;
			}
		}
		.topMenu {
			img {
				margin-bottom:20px;
			}
		}
	}

}